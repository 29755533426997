import { creators as userCreators } from '../ducks/userDuck';
import { creators as organizationCreators } from '../ducks/organizationDuck';
import { tokenDuck } from '../../store/jwtMiddleware';
import { getConfigurationAction } from './configuration';

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

const rolePriority = ['admin', 'deployer', 'operator', 'monitor'];

const parseToken = access_token => JSON.parse(atob(access_token.split('.')[1]));

export const mapRoles = accessTokenParsed => {
  const roles = (accessTokenParsed.sws_permissions || []).filter(p => p.startsWith('connect.'));
  const collapsed = roles.reduce((acc, item) => {
    const [, organization, role] = item.split('.');
    if (
      acc[organization] &&
      rolePriority.indexOf(role) >= 0 &&
      rolePriority.indexOf(role) < rolePriority.indexOf(acc[organization])
    ) {
      acc[organization] = role;
    } else if (!acc[organization] && rolePriority.indexOf(role) >= 0) {
      acc[organization] = role;
    }
    return acc;
  }, {});
  return Object.keys(collapsed).map(key => ({
    role: capitalize(collapsed[key]),
    organizationId: key,
    organizationShortName: key,
  }));
};

export const getRoles = (profile, access_token) => {
  const parsedToken = parseToken(access_token);
  const rolesAndOrganizations = mapRoles(parsedToken);
  return rolesAndOrganizations.map(rog => ({
    role: rog.role,
    userId: profile.sub,
    organizationId: rog.organizationId,
    organizationDetails: {
      name: rog.organizationShortName,
      shortName: rog.organizationShortName,
    },
  }));
};

const mapUser = ({ profile, access_token }) => {
  const roles = getRoles(profile, access_token);
  return {
    userId: profile.sub,
    email: profile.email,
    name: profile.name,
    organizationId: roles[0] && roles[0].organizationId,
    roles: roles,
  };
};

const mapOrganization = user => {
  return {
    organizationId: user.roles[0].organizationId,
    organizationName: user.roles[0].organizationDetails.name,
    organizationShortName: user.roles[0].organizationDetails.shortName,
    managementApiEnabled: true,
  };
};

export const initializeFoundation = userInfo => async (dispatch, getState) => {
  const user = mapUser(userInfo);
  if (user.roles.length === 0) {
    dispatch(tokenDuck.creators.setInvalidRoles());
    return;
  }
  const organization = mapOrganization(user);
  dispatch(userCreators.fetchSuccess(user));
  dispatch(organizationCreators.fetchSuccess(organization));
  await dispatch(getConfigurationAction(userInfo.access_token));
  dispatch(
    tokenDuck.creators.setToken(userInfo.access_token, 'refreshToken', new Date(userInfo.expires_at * 1000).toString())
  );
};
