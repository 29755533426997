import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import createZitadelAuth from 'config/zitadel';
import Loading from '../../components/Loading';

const Callback = () => {
  const [authorized, setAuthorized] = useState(null);

  const zitadel = createZitadelAuth();

  useEffect(() => {
    zitadel.userManager
      .signinCallback()
      .then(user => {
        if (user) {
          setAuthorized(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [zitadel]);
  if (authorized === true) {
    return <Navigate to="/" replace />;
  }
  return <Loading />;
};

export default Callback;
