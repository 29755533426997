import React, { useMemo, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useOrganizationShortNameHandler } from './useOrganizationShortNameHandler';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import Loading from '../../../components/Loading';
import NoRoles from '../../../components/NoRoles';
import { useZitadelContext } from '../../../components/ZitadelProvider';

const Initialize = ({
  children,
  user,
  organizationShortName,
  featuresInitialized,
  configurationInitialized,
  token,
  fetchFeatures,
  initialize,
  initializeFoundation,
  invalidRoles,
}) => {
  const location = useLocation();
  const foundationFeature = useFeatureToggle(FEATURE_TOGGLES.FOUNDATION);
  useOrganizationShortNameHandler(organizationShortName);

  const { user: zitadelUser, signout } = useZitadelContext();

  useEffect(() => {
    if (foundationFeature && zitadelUser && !zitadelUser.expired) {
      initializeFoundation(zitadelUser);
    } else if (token && !foundationFeature) {
      initialize();
    }
  }, [initialize, foundationFeature, token, zitadelUser, initializeFoundation]);

  useEffect(() => {
    if (foundationFeature && !!organizationShortName && !!token) {
      fetchFeatures();
    } else if (!!organizationShortName && !!token) {
      fetchFeatures();
    }
  }, [foundationFeature, fetchFeatures, organizationShortName, token]);

  const isDoneLoading = useMemo(
    () => !!user.userId && !!organizationShortName && featuresInitialized && configurationInitialized,
    [user, organizationShortName, featuresInitialized, configurationInitialized]
  );

  if (invalidRoles) {
    return <NoRoles signout={signout} />;
  }

  if (!token && !foundationFeature) {
    return (
      <Navigate to={{ pathname: '/login', state: { from: location.pathname + (location.search || '') } }} replace />
    );
  } else if (isDoneLoading) {
    return children;
  } else {
    return <Loading />;
  }
};

export default Initialize;
